import React from 'react';

function LoadingSpinnerComponent() {
    return (
        <div className='d-flex justify-content-center mt-5'>
            <div className='spinner-border white-text'></div>
        </div>
    );
}

export default LoadingSpinnerComponent;