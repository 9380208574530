import React, { useState } from 'react';
import './App.css';

import LoadingSpinnerComponent from './components/LoadingSpinnerComponent';
import AlertComponent from './components/AlertComponent';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import logo from './resources/rxarge_logo.svg'
import { getStationsByLocation } from './Networking/API';

const networking = require('./Networking/API');

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [cities, setCities] = useState(null) as any[];
  const [stations, setStations] = useState(null) as any[];
  const [usageStations, setUsageStations] = useState(null) as any[];
  const [noUsageStations, setNoUsageStations] = useState(null) as any[];
  const [currentCity, setCurrentCity] = useState(-1);
  const [totalStations, setTotalStations] = useState(null) as any[];

  const [count, setCount] = useState(0);

  var operators: any[] = [];
  var allOperators: any[] = [];

  if(!cities) {
    getCities();
  }

  function getCities() {
    networking.getCities().then((list: any) => {
      setCities(list);
      setLoading(false);
    }).catch((error: Error) => {
      setError(error.message);
      setLoading(false);
    });
  }

  function getAllStationsFor(city:any) {
    setLoading(true);
    setCount(count + 1);

    networking.getStationsByLocation(city).then((data: any) => {
      setTotalStations(data.content);
      setCount(count - 1);
    }).catch((error: Error) => {
      setError(error.message);
      setLoading(false);
      setCount(count - 1);
    });
  }

  function getUsedDataFor(city: any) {
    setLoading(true);
    networking.getDataForLocation(city, 'usagePercentage').then((data: any) => {
      setLoading(false);
      setCount(count - 1);
      setUsageStations(data.content);
    }).catch((error: Error) => {
      setError(error.message);
      setLoading(false);
      setCount(count - 1);
    });
  }

  function getUnusedDataFor(city: any) {
    networking.getDataForLocation(city, 'noUsagePercentage').then((data: any) => {
      setLoading(false);
      setNoUsageStations(data.content);
      setCount(count - 1);
    }).catch((error: Error) => {
      setError(error.message);
      setLoading(false);
      setCount(count - 1);
    });
  }

  function getDataFor(city: any) {
    setLoading(true);
    setCount(count + 3);

    networking.getDataForLocation(city, 'outOfOrderPercentage').then((data: any) => {
      setLoading(false);
      setCount(count - 1);
      setStations(data.content);
    }).catch((error: Error) => {
      setError(error.message);
      setLoading(false);
      setCount(count - 1);
    });
  }

  function filterByCity(e:any) {
    e.preventDefault();

    const value = e.target.innerText;

    setCurrentCity(value);
    getAllStationsFor(value);
    getDataFor(value);
    getUsedDataFor(value);
    getUnusedDataFor(value);
  }

  var operatorsCount: any[] = [];
  var operatorsCodes: any[] = [];
  var allOperatorsCodes: any[] = [];
  var allOperatorsCodesUsed: any[] = [];
  var allOperatorsCodesUnused: any[] = [];
  var allStationPosts: any[] = [];

  if (stations) {
    operators = [... new Set(stations.map((cp:any) => cp.stationData.operatorName))];

    operators.map((s: string) => {
      operatorsCodes.push(s);
      operatorsCount.push(
        stations.filter((cp: any) => cp.stationData.operatorName === s).length
      );
    })
  }

  if (usageStations) {
    operators = [... new Set(usageStations.map((cp:any) => cp.stationData.operatorName))];

    operators.map((s: string) => {
      allOperatorsCodesUsed.push(
        usageStations.filter((cp: any) => cp.stationData.operatorName === s).length
      );
    })
  }

  if (noUsageStations) {
    operators = [... new Set(noUsageStations.map((cp:any) => cp.stationData.operatorName))];

    operators.map((s: string) => {
      allOperatorsCodesUnused.push(
        noUsageStations.filter((cp: any) => cp.stationData.operatorName === s).length
      );
    })
  }

  if(totalStations) {
    allOperators = [... new Set(totalStations.map((cp:any) => cp.stationData.operatorName))];

    allOperators.map((s: string) => {
      allOperatorsCodes.push({ 'x': s, y: totalStations.filter((cp: any) => cp.stationData.operatorName === s).length});
    })

    totalStations.map((s:any) => {
      allStationPosts.push(...s.stationData.chargingPoints);
    })
  }

  const options: ApexOptions = {
              chart: {
                type: 'polarArea'
              },
              labels: operatorsCodes,
              fill: {
                opacity: 1
              },
              yaxis: {
                show: false
              },
              legend: {
                show: false,
              },
              plotOptions: {
                polarArea: {
                  rings: {
                    strokeWidth: 1
                  },
                  spokes: {
                    strokeWidth: 2
                  },
                }
              },
              theme: {
                monochrome: {
                  enabled: true,
                  shadeTo: 'light',
                  color:'#00BDAA',
                  shadeIntensity: 1
                }
              }
  }

  const data = {
    series: operatorsCount
  }

  const dataUsed = {
    series: allOperatorsCodesUsed
  }

  const dataUnused = {
    series: allOperatorsCodesUnused
  }

  const allOptions: ApexOptions = {
        legend: {
          show: false
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px'
          }
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          treemap: {
            enableShades: true,
          }
        }
      }

  const allData = {
    series: [
      {
        data: allOperatorsCodes
      }
    ]
  }

  return (
    <div>
    <nav className='navbar bg-dark navbar-expand-lg p-3 my-auto'>
      <a href='#home' className='navbar-brand'>
        <img height={ 30 } src={ logo } alt='logo'></img>
      </a> 
    </nav>
      <div className="App">
      <p className='mt-3 hint white-text'>Using data from 29th April until today.</p>
        <div className='bg-green p-3'>
          { loading ?
          <LoadingSpinnerComponent/>
                  :
          <div className='dropdown mb-1'>
            <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
              <span className="me-2"> { currentCity === -1 ? "Choose a city" : currentCity } </span>
            </button>
            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              {
                cities && cities.map((city: string) => {
                  return (
                    <li><a onClick={(e) => filterByCity(e)} className='dropdown-item'>{ city }</a></li>
                    );
                  })
                  }
            </ul>
          </div>
        }
        </div>
      { loading && count != 0 ?
          <LoadingSpinnerComponent/>
                  :
                  
        <div>
          { currentCity != -1 && 
          <div className='p-5'>     
            <div className='mb-5'>
              <div className='card p-5'>
                <div className='row'>
                  <div className='col-auto my-auto me-2'>
                    <p className='small-title'>STATIONS</p>
                    <p className='big-header mb-0'>{ totalStations && totalStations.length }</p>            
                  </div>
                  <div className='col-auto my-auto'>
                    <p className='small-title'>Charging points</p>
                    <p className='big-header mb-0'>{ allStationPosts && allStationPosts.length }</p>
                  </div>
                  <div className='col mx-auto'>
                    <ReactApexChart options={ allOptions } series={ allData.series } type="treemap" height={180} />
                  </div>
                </div>
              </div>
            </div>
            
            <div className='row'>
              <div className='col'>
                <div className='text-center mb-4 white-text'><b>MOST OUT OF ORDER</b></div>
                { stations && stations.slice(0,5).map((station: any) => {
                    return (
                      <div className='card mb-3'>
                        <div className=''>
                        { station.outOfOrderPercentage > 0.10 &&
                              <div className='col alert-red'></div>
                          }
                          { station.outOfOrderPercentage < 0.10 &&
                              <div className='col alert-green'></div>
                          }
                        </div>
                        <div className='row text-start p-3'>                  
                          <div className='col-auto'>
                            <div className='big'><span className='small'>%</span>{ Math.round(station.outOfOrderPercentage * 100) }</div>
                            <span className='small-title'> OUT OF ORDER</span>
                          </div>
                          <div className='col'>
                            <div className='row text-end'>
                              <div className='col-auto'>
                                { station.stationData.name }
                              </div>
                            </div>
                            <div className='row text-start'>
                              <div className='col-auto'>{ station.stationData.operatorName }</div>
                              <div className='col'>{ station.stationData.operatorCode }</div>
                            </div>
                            <div className=''>
                              { station.stationData.address }, { station.stationData.postalCode }
                            </div>
                            <div className=''>
                              { station.stationData.chargingPoints.length } CHARGING POINTS
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) 
                }
              </div>
              <div className='col'>          
                <div className='text-center mb-4 white-text'><b>MOST USED</b></div>
                { usageStations && usageStations.slice(0,5).map((station: any) => {
                    return (
                      <div className='card mb-3'>
                        <div className=''>
                        { station.usagePercentage < 0.10 &&
                              <div className='col alert-red'></div>
                          }
                          { station.usagePercentage > 0.10 &&
                              <div className='col alert-green'></div>
                          }
                        </div>
                        <div className='row text-start p-3'>                  
                          <div className='col-auto'>
                            <div className='big'><span className='small'>%</span>{ Math.round(station.usagePercentage * 100) }</div>
                            <span className='small-title'> USAGE</span>
                          </div>
                          <div className='col'>
                            <div className='row text-end'>
                              <div className='col-auto'>
                                { station.stationData.name }
                              </div>
                            </div>
                            <div className='row text-start'>
                              <div className='col-auto'>{ station.stationData.operatorName }</div>
                              <div className='col'>{ station.stationData.operatorCode }</div>
                            </div>
                            <div className=''>
                              { station.stationData.address }, { station.stationData.postalCode }
                            </div>
                            <div className=''>
                              { station.stationData.chargingPoints.length } CHARGING POINTS
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) 
                }
              </div>
              <div className='col'>      
                <div className='text-center mb-4 white-text'><b>MOST UNUSED</b></div>
                { noUsageStations && noUsageStations.slice(0,5).map((station: any) => {
                    return (
                      <div className='card mb-3'>
                        <div className=''>
                        { station.noUsagePercentage > 0.10 &&
                              <div className='col alert-red'></div>
                          }
                          { station.noUsagePercentage < 0.10 &&
                              <div className='col alert-green'></div>
                          }
                        </div>
                        <div className='row text-start p-3'>                  
                          <div className='col-auto'>
                            <div className='big'><span className='small'>%</span>{ Math.round(station.noUsagePercentage * 100) }</div>
                            <span className='small-title'> NO USAGE</span>
                          </div>
                          <div className='col'>
                            <div className='row text-end'>
                              <div className='col-auto'>
                                { station.stationData.name }
                              </div>
                            </div>
                            <div className='row text-start'>
                              <div className='col-auto'>{ station.stationData.operatorName }</div>
                              <div className='col'>{ station.stationData.operatorCode }</div>
                            </div>
                            <div className=''>
                              { station.stationData.address }, { station.stationData.postalCode }
                            </div>
                            <div className=''>
                              { station.stationData.chargingPoints.length } CHARGING POINTS
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) 
                }
              </div>
            </div>
          </div>
          }
          { currentCity != -1 && 
          <div className='ps-5 pe-5'>
            <div className='row'>
              <div className='col'>
                <div className='card pt-5 pb-5'>
                  <div className='small mb-4'><b>TOP 10 OUT OF ORDER OPERATOR DISTRIBUTION</b></div>
                  <ReactApexChart options={ options } series={ data.series } type="polarArea" width={400} />
                </div>
              </div>
              <div className='col'>
                <div className='card pt-5 pb-5'>
                  <div className='small mb-4'><b>TOP 10 MOST USED OPERATOR DISTRIBUTION</b></div>
                  <ReactApexChart options={ options } series={ dataUsed.series } type="polarArea" width={400} />
                </div>
                </div>
              <div className='col'>
                <div className='card pt-5 pb-5'>
                  <div className='small mb-4'><b>TOP 10 MOST UNUSED OPERATOR DISTRIBUTION</b></div>
                  <ReactApexChart options={ options } series={ dataUnused.series } type="polarArea" width={400} />
                </div>
              </div>
            </div>
          </div> 
          }
      </div>
      }
    </div>
    </div>
  );
}

export default App;
