const host = "https://otw-ocpi-mobinteli-api.herokuapp.com/"

export const getCities = async function() {
    const endpoint = "https://json.geoapi.pt/municipios";

    const response = await fetch(
        endpoint, {
            method:'GET'
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const success = await response.json();

    return success;
}

export const getStationsByLocation = async function(location: string) {
    const endpoint = host + "EnergyInfrastructureStats/sites/" + location;

    const response = await fetch(endpoint);

        if(!response.ok) {
            const message = 'Something went wrong here. Code: ' + response.status;
            throw new Error(message);
        }

        const success = await response.json();

        return success;

}

export const getDataForLocation = async function(location: string, order: string) {
    const endpoint = host + "EnergyInfrastructureStats/usage/";

    const response = await fetch(
        endpoint,
        {
            method:'POST',
            headers: {
                'Acess-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( {
                "perPage": 10,
                "page": 0,
                "orderBy": order,
                "isOrderByDescending": true,
                "stationLocation": location
            } )
        });

        if(!response.ok) {
            const message = 'Something went wrong here. Code: ' + response.status;
            throw new Error(message);
        }

        const success = await response.json();

        return success;

}

export const getusagePercentageDataForLocation = async function(location: string) {
    const endpoint = host + "EnergyInfrastructureStats/usage/";

    const response = await fetch(
        endpoint,
        {
            method:'POST',
            headers: {
                'Acess-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( {
                "perPage": 10,
                "page": 0,
                "orderBy": "usagePercentage",
                "isOrderByDescending": true,
                "stationLocation": location
            } )
        });

        if(!response.ok) {
            const message = 'Something went wrong here. Code: ' + response.status;
            throw new Error(message);
        }

        const success = await response.json();

        return success;

}
